<template>
  <div class="Home">
    <el-container class="elContainer">
      <!-- 顶部栏 -->
      <el-header id="header" style="height: auto">
        <div class="row">
          <div class="container">
            <!-- <img src="@/assets/logo@2x.png" class="logo" /> -->
            <div class="logo"></div>
            <div class="search">
              <transition name="slide-fade">
                <div class="re flex-jse" v-if="!isIcon">
                  <input
                    type="text"
                    v-model="search"
                    class="searchInp"
                    placeholder="请输入内容"
                    @keyup.enter="goSearch"
                    v-focus
                  />
                  <img
                    src="@/assets/ic_ss2@2x.png"
                    class="show-search-img"
                    alt=""
                  />
                  <div @click="goSearch" class="searchButton"></div>
                </div>
              </transition>
              <img
                src="@/assets/ic_ss@2x.png"
                class="searchImg"
                alt="搜索"
                v-show="isIcon"
                @click="showSearch"
              />
            </div>
            <div class="nav">
              <div
                @click="switchNav(index)"
                class="navCard"
                :style="{
                  color: active == item.id ? '#E41414' : '#949494',
                  width: item.id == 4 ? '66px' : '45px',
                }"
                v-for="(item, index) in navCard"
                :key="'nav' + index"
              >
                <div class="newsNum" v-if="item.id == 4 && newsNumber > 0">
                  {{ newsNumber }}
                </div>
                <div>{{ item.title }}</div>
                <div class="active" :class="{ opt0: active != item.id }"></div>
              </div>
            </div>
            <div class="flex-alc re ml-18 click" id="info" v-show="user.name">
              <el-avatar
                class="mb-13"
                :src="user.avatar || require('@/assets/defulatAvatar.png')"
              ></el-avatar>
              <div class="ml-14 mb-13 userName">
                {{ user.name }}
              </div>
              <div class="mine">
                <div class="triangle"></div>
                <!-- <div class="click info" @click="toMyInfo">
                                    个人中心
                                </div>
                                <el-divider></el-divider> -->
                <div class="click logout" @click="loginOut">退出</div>
              </div>
            </div>
            <div
              class="flex-alc re ml-28 click"
              v-show="!user.name"
              @click="login"
            >
              <el-avatar
                class="mb-13"
                src="@/assets/defulatAvatar.png"
              ></el-avatar>
              <div class="ml-14 mb-13 login">点击扫码登录</div>
            </div>
          </div>
        </div>
      </el-header>
      <!-- 内容 -->
      <el-main id="main">
        <div id="seat-header"></div>
        <router-view v-wechat-title="$route.meta.title" />
      </el-main>
      <!-- 底部栏 -->
      <el-footer id="footer" style="height: auto">
        <div class="footer">
          <div class="container">
            <div class="flex-als between">
              <div class="flex-als">
                <div
                  class="toHref"
                  v-for="(item, index) in link"
                  :key="'link' + index"
                >
                  <span v-if="index != 0" class="mr-8 ml-8">|</span>
                  <router-link :to="item.url">{{ item.name }}</router-link>
                </div>
              </div>
              <!-- <img src="@/assets/wxQr.jpg" class="weixin" alt="" /> -->
            </div>
            <div class="tanget"></div>
            <div class="copyright">
              <!-- {{ "Copyright © 2020 彰泰集团人力行政中心" }} -->
              {{ "Copyright © 2020 " }}
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      headerHeight: 0,
      footerHeight: 0,
      search: "", //搜索内容
      isIcon: true, //显示搜索icon
      navCard: [
        {
          title: "首页",
          id: 0,
          url: "/",
        },
        {
          title: "课程",
          id: 1,
          url: "/CurriculumTypeList",
        },
        {
          title: "地图",
          id: 2,
          url: "/myInfo/map?source=0",
        },
        {
          title: "测评",
          id: 3,
          url: "/Evaluation",
        },
        {
          title: "个人中心",
          id: 4,
          url: "/Evaluation",
        },
      ],
      link: [
        {
          name: "网站首页",
          url: "/",
        },
        {
          name: "全部课程",
          url: "/CurriculumTypeList",
        },
        {
          name: "联系我们",
          url: "/",
        },
        {
          name: "友情链接",
          url: "/",
        },
        {
          name: "关于",
          url: "/",
        },
        // {
        //   name: "关于彰泰",
        //   url: "/",
        // },
      ],
    };
  },

  computed: {
    active: function() {
      //0首页 1课程 2地图 3测评 4个人中心
      return this.$store.state.navActive;
    },
    user: function() {
      return this.$store.state.user;
    },
    newsNumber: function() {
      return this.$store.state.newsNum;
    },
  },

  async mounted() {
    let login = this.$route.query.state;
    //获取个人信息
    if (login || window.localStorage.getItem("user")) {
      await this.$https.get("/api/qy_wx_userinfo").then((res) => {
        window.localStorage.setItem("user", JSON.stringify(res.data));
        this.$store.commit("setUser");
        this.$https.get("/api/message/list").then((rep) => {
          if (res.errcode) return;
          this.$store.commit("newsNumber", rep.data.unread || 0);
        });
      });
    }
    console.log("login", this.$store.state.user.id);
    //获取七牛token
    this.$https.get("/api/ali/oss").then((res) => {
      window.localStorage.setItem("ossToken", JSON.stringify(res));
      this.$store.commit("setOssToken");
    });
    this.$store.commit(
      "switchNav",
      window.localStorage.getItem("navActive") || 0
    );
    //动态改变mian高度
    let isResize = true;
    this.headerHeight = document.getElementById("header").offsetHeight;
    this.footerHeight = document.getElementById("footer").offsetHeight;
    document.getElementById("seat-header").style.height =
      this.headerHeight + "px";
    window.onresize = () => {
      if (isResize) {
        isResize = false;
        this.setMainHeight();
        setTimeout(() => {
          isResize = true;
        }, 200);
      }
    };
    this.setMainHeight();

    let _beforeUnload_time = 0,
      _gap_time = 0;
    window.addEventListener("onbeforeunload", () => {
      _beforeUnload_time = new Date().getTime();
      console.log("浏览器关闭前");
    });
    window.addEventListener("onunload", () => {
      _gap_time = new Date().getTime() - _beforeUnload_time;
      if (_gap_time <= 5) {
        console.log("浏览器关闭");
        this.loginOut();
      }
    });
    window.onbeforeunload = () => {
      _beforeUnload_time = new Date().getTime();
      console.log("浏览器关闭前");
    };
    window.onunload = () => {
      _gap_time = new Date().getTime() - _beforeUnload_time;
      if (_gap_time <= 5) {
        console.log("浏览器关闭");
        this.loginOut();
      }
    };
  },

  methods: {
    //登录
    login() {
      window.location.href = "/api/qyloginurl";
    },
    //退出
    loginOut() {
      this.$https.get("/api/login_out").then((res) => {
        if (res.errcode) return this.$message.error(res.msg);
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("navActive");
        window.localStorage.removeItem("myNavActive");
        this.$store.commit("setUser");
        sessionStorage.clear();
        this.delCookie();
        this.$router.replace({ path: "/" });
        console.log("退出成功", this.$store.state.user);
      });
    },
    delCookie() {
      let keys = document.cookie.match(/[^ =;]+(?==)/g);
      if (keys) {
        for (let i = keys.length;i--;) {
          document.cookie =
            keys[i] +
            "=0;path=/;expires=" +
            new Date(0).toUTCString(); // 清除当前域名下的,例如：m.ratingdog.cn
          document.cookie =
            keys[i] +
            "=0;path=/;domain=" +
            document.domain +
            ";expires=" +
            new Date(0).toUTCString(); // 清除当前域名下的，例如 .m.ratingdog.cn
          document.cookie =
            keys[i] +
            "=0;path=/;domain=.yuhuofei.cn;expires=" +
            new Date(0).toUTCString(); // 清除一级域名下的或指定的，例如 .ratingdog.cn
        }
      }
    },
    //个人中心
    toMyInfo() {
      let urlList = [
        "/myInfo/integral",
        "/myInfo/favorite",
        "/myInfo/master",
        "/myInfo/apprentice",
        "/myInfo/lecturer",
        "/myInfo/map?source=1",
        "/myInfo/certificate",
        "/myInfo/News",
        "/myInfo/Target",
      ];
      if (this.$store.state.myActive == -2) {
        window.localStorage.setItem("myNavActive", -1);
      }
      if (this.$store.state.myActive == -1) {
        this.$router.push({ path: "/myInfo" });
      } else {
        this.$router.push({
          path: urlList[this.$store.state.myActive],
        });
      }
      // window.localStorage.setItem("navActive", -1);
      // this.$store.commit("switchNav", -1);
    },
    //进行搜索
    goSearch() {
      if (!this.search) return;
      this.$router.push({
        path: "/searchresult",
        query: { search: this.search },
      });
    },
    //显示搜索框
    showSearch() {
      this.isIcon = false;
    },
    //导航栏切换跳转
    switchNav(e) {
      window.localStorage.setItem("navActive", e);
      this.$store.commit("switchNav", e);
      if (e == 4) {
        return this.toMyInfo();
      }
      this.$router.push({ path: this.navCard[e].url });
    },
    //设置main最小高度
    setMainHeight() {
      let windowHeight = window.innerHeight;
      let mainHeight = windowHeight - this.footerHeight;
      document.getElementById("main").style.minHeight = mainHeight + "px";
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.Home {
  min-width: 1200px;
  .elContainer {
    .el-header {
      padding: 0;
      margin: 0 auto;
      position: fixed;
      top: 0;
      left: 0;
      background: white;
      z-index: 99;
      width: 100%;

      .row {
        width: 1200px;
        margin: auto;

        /deep/.container {
          display: flex;
          margin: 0 auto;

          .logo {
            width: 329px;
            height: 90px;
          }
          .search {
            width: 245px;
            min-width: 210px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 10px;

            .searchInp {
              position: absolute;
              right: 45px;
              top: 0;
              bottom: 0;
              border: none;
              height: 28px;
              margin: auto;
              background: #fff3f3;
              outline: none;
              width: 151px;
            }

            .show-search-img {
              width: 209px;
              height: 48px;
            }

            .searchButton {
              width: 38px;
              height: 38px;
              position: absolute;
              right: 3px;
              top: 0;
              bottom: 0;
              margin: auto;
              cursor: pointer;
            }

            .searchImg {
              width: 48px;
              height: 48px;
              cursor: pointer;
            }
          }
          .nav {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 440px;
            justify-content: space-between;

            .navCard {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin: 0 11px;
              cursor: pointer;
              color: #949494;
              width: 45px;
              position: relative;

              .newsNum {
                position: absolute;
                top: -6px;
                right: -12px;
                width: 16px;
                height: 16px;
                border-radius: 100%;
                background: #ff3143;
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .active {
              width: 33px;
              height: 5px;
              background: #e41414;
              border-radius: 3px;
              margin-top: 9px;
            }
          }

          .el-avatar {
            min-width: 40px;
            max-width: 41px;
          }
          #info:hover .mine {
            display: block;
          }
          .userName {
            font-size: 16px;
            font-weight: 500;
            color: #2d2d2d;
            white-space: nowrap;
          }
          .login {
            font-size: 16px;
            font-weight: 500;
            color: #2d2d2d;
            white-space: nowrap;
          }
          .mine {
            display: none;
            width: 90px;
            position: absolute;
            bottom: -20px;
            left: 0px;
            background: #efefef;
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            border-radius: 10px;
            padding: 10px 15px;
            box-shadow: 0px 0px 2px 0px #fff;
            z-index: 99;
            .triangle {
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 13px solid #efefef;
              position: absolute;
              right: 12px;
              top: -13px;
              z-index: 90;
            }
            .el-divider--horizontal {
              margin: 5px 0;
              height: 2px;
            }
            .info {
              width: 100%;
              text-align: center;
            }
            .logout {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }

    .el-main {
      padding: 0;
      background: #f6f6f6;
    }

    .el-footer {
      padding: 0;
      background: #272727;

      .footer {
        width: 1200px;
        margin: 0 auto;

        .container {
          display: flex;
          flex-direction: column;
          padding: 46px 0 29px 0;

          .toHref {
            font-size: 16px;
            font-weight: 400;
            color: #878787;
            display: flex;
            align-items: center;

            a {
              color: #878787;
              text-decoration: none;
            }
          }
          .weixin {
            width: 81px;
            height: 81px;
          }
          .tanget {
            width: 100%;
            height: 2px;
            border: 1px solid #4c4c4c;
            margin: 48px 0 27px 0;
          }
          .copyright {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #a4a4a4;
          }
        }
      }
    }
  }
}
</style>
